$(function(){
  $('.menu-trigger').on('click',function(){
    if($(this).hasClass('active')){
      $(this).removeClass('active');
      $(this).next('ul').removeClass('open');
      $('.overlay').removeClass('open');
    } else {
      $(this).addClass('active');
      $(this).next('ul').addClass('open');
      $('.overlay').addClass('open');
    }
  });
  $('.overlay').on('click',function(){
    if($(this).hasClass('open')){
      $(this).removeClass('open');
      $('.menu-trigger').removeClass('active');
      $('.nav-sp ul').removeClass('open');
    }
  });
  // lang
  if ($('body').hasClass('en')) {
    $('#lang-en').addClass('current');
  } else {
    $('#lang-jp').addClass('current');
  }

  // //navの黒背景表示・非表示
  // function displayNavBg(){
  //   var scrollTop = $(window).scrollTop();
  //   if (scrollTop < 10) {
  //     $(".navbar-bg").slideUp();
  //   } else {
  //     $(".navbar-bg").slideDown();
  //   }
  // }
  // $(window).on("load scroll", displayNavBg);


  // // ローカルナビゲーション
  // $("#localnav a").smoothScroll({
  //   offset: -60
  // });

  // スマホ以外電話リンクOFF
  var ua = navigator.userAgent.toLowerCase();
  var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);
  if (!isMobile) {
      $('a[href^="tel:"]').on('click', function(e) {
          e.preventDefault();
      }).addClass('disable');
  }

   $('#menu .menu, a.btn, #navbar a').on('touchstart', function(){
     $(this).addClass('hover');
   }).on('touchend', function(){
     $(this).removeClass('hover');
   });


});


$(window).on("load resize", function(){
  if ($('body').hasClass('slick')) {
    $('.slider').slick({
      arrows: false,
      autoplay:true,
      autoplaySpeed:5000,
      dots:true,
      pauseOnHover: false,
      speed: 1005,
      lazyLoad: 'progressive'
    });
  }
});

$(window).on("load resize", mainImage);
function mainImage(){
  if ($('body').hasClass('works-detail')) {
    $('.asset-header img').removeAttr('height');
    $('.asset-header img').removeAttr('width');
    var mainImageW = $('.asset-header img').width();
    var mainImageH = $('.asset-header img').height();
    var mainImageRatio = mainImageH / mainImageW;
    var windowWidth = $(window).width();
    var assetW = $('.asset-header').width();
    // console.log(mainImageRatio +'　'+ mainImageW +'　'+ mainImageH +'　'+ windowWidth);
    if (windowWidth-assetW > 0) {
      $('.asset-header img').css({
        'left': -(windowWidth-assetW)/2+ 'px',
        'width':windowWidth+ 'px'
      });
    }
    $('.asset-header').css('paddingTop', windowWidth*mainImageRatio+20+ 'px');
    $('.asset-body img').addClass('img-fluid');
  }
}

//toTopの表示・非表示
function displayToTop(){
  var scrollTop = $(window).scrollTop();
  if (scrollTop < 300) {
    $(".to-page-top").fadeOut();
  } else {
    $(".to-page-top").fadeIn();
    $(".to-page-top a").smoothScroll({offset: -80});
  }
}
$(window).on("load scroll", displayToTop);

$(window).on("load resize", function(){
  if ($('body').hasClass('home')) {
    if ($(window).width()<992) {
      var windowH = $(window).height();
      var langageH = $('#langage').height();
      var featureH = $('#feature').height();
      var featurePadding = (windowH-langageH-featureH)/2;
      $('#feature').css({
        'paddingTop': featurePadding+'px',
        'paddingBottom': featurePadding+'px'
      });
    }
    if ($(window).width()>991) {
      var windowH = $(window).height();
      var langageH = $('#menu').height();
      var featureH = 0;
          featureH = $('#feature').height();
      var featurePadding = (windowH-langageH-featureH)/2;
      // console.log(windowH+','+langageH+','+featureH+','+featurePadding);
      $('#feature').css({
        'paddingTop': featurePadding+'px',
        'paddingBottom': featurePadding+'px'
      });
    }
  }
});

// $(window).on("load resize scroll", function(){
//   var scrollTop2 = $(window).scrollTop();
//   var headlineHight = $('#headline').height();
//   // console.log(scrollTop2+', '+ headlineHight);
//   if (scrollTop2 > headlineHight -25) {
//     $('.nav-sp').addClass('colorChange');
//   } else{
//     $('.colorChange').removeClass('colorChange');
//   }
// });
